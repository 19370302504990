/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    hr: "hr",
    div: "div",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A controlled gate is a gate in quantum computing where the state of one qubit (the control qubit) determines whether a single-qubit unitary operation is applied to a second qubit (the target qubit)."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "In a circuit, a controlled gate looks like the image below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 51.26582278481012%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABJ0AAASdAHeZh94AAAAoUlEQVQoz5VS7QoEIQjs4XurHqf9FxS19AnlYVyHRHW7glha5szE4GuttV8cTvOrM/MajQExWqB7pRR477cPUGNzsdYKKaXuIYSe45yDEKKvMRdjhFLKecIV5JGz1kLOeUvJa8g4DU7+CvKpIU53EunI4QwJudRagzHmncpUlPu+wTnXIza7rguklH0/akOwxxxS/vDLYIMdJY8hry78U/kDZggWNpWCSooAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"controlled-gate-symbol\"\n        title=\"controlled-gate-symbol\"\n        src=\"/static/e414da79f229fd8a93949054b516ebe4/f058b/controlled-u-gate-symbol.png\"\n        srcset=\"/static/e414da79f229fd8a93949054b516ebe4/c26ae/controlled-u-gate-symbol.png 158w,\n/static/e414da79f229fd8a93949054b516ebe4/6bdcf/controlled-u-gate-symbol.png 315w,\n/static/e414da79f229fd8a93949054b516ebe4/f058b/controlled-u-gate-symbol.png 630w,\n/static/e414da79f229fd8a93949054b516ebe4/a016c/controlled-u-gate-symbol.png 762w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Here, the first qubit is the control qubit and the second is the target, where the single-qubit unitary operation $U$ is applied to the target if the control qubit is in the $\\vert 1 \\rangle$ state (denoted by the filled circle)."), "\n", React.createElement(_components.p, null, "The full matrix representation of this controlled gate is"), "\n", React.createElement(_components.p, null, "$$\n\\left[ \\begin{matrix}1 & 0 & 0 & 0 \\\\ 0 & 1 & 0 & 0 \\\\ 0 & 0 & U_{1,1} & U_{1,2} \\\\ 0 & 0 & U_{2,1} & U_{2,2} \\end{matrix} \\right],\n$$"), "\n", React.createElement(_components.p, null, "with the different components of the unitary $U$ occupying the lower right spaces."), "\n", React.createElement(_components.p, null, "The controlled gate can also be arranged the other way around, with the control qubit being the second qubit and the target being the first, as shown below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 53.16455696202532%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABJ0AAASdAHeZh94AAAAsElEQVQoz61R7QqEIBDslX3DnqIHkCD/pRJipc6xwop4FnfdLQzCfoyzswMApJTAb4s6erW2Z6ibexFCyIgxvtV6M0OrkIbP8yxYlgVKKRhjMinn+YOuwprwOA7s+14wzzOklNBaZyLO08ePVnbOZXVE9mjlnune+/8d5S4+UkhmE9ijdV0xTVO5ONdvFXKSGrdtg7U2+0YE4zhCCJHXpjyjtuHrlVntz0epB65qrcIX3g5k3rUK3MAAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"controlled-u-gate-symbol-reversed\"\n        title=\"controlled-u-gate-symbol-reversed\"\n        src=\"/static/2d1b6286c2aad770db13c56b2fc63b1d/f058b/controlled-u-gate-symbol-reversed.png\"\n        srcset=\"/static/2d1b6286c2aad770db13c56b2fc63b1d/c26ae/controlled-u-gate-symbol-reversed.png 158w,\n/static/2d1b6286c2aad770db13c56b2fc63b1d/6bdcf/controlled-u-gate-symbol-reversed.png 315w,\n/static/2d1b6286c2aad770db13c56b2fc63b1d/f058b/controlled-u-gate-symbol-reversed.png 630w,\n/static/2d1b6286c2aad770db13c56b2fc63b1d/0f2bc/controlled-u-gate-symbol-reversed.png 742w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Controlled gates can be created ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/code/api/pennylane.ctrl.html"
  }, "in PennyLane"), " using ", React.createElement(_components.code, null, "qml.ctrl"), ", and ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit/qiskit.circuit.ControlledGate"
  }, "in Qiskit"), " using ", React.createElement(_components.code, null, "ControlledGate"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
